exports.getAdminUsername = () => {
    const s = String.fromCharCode
    const o = [103, 117, 108, 97, 109, 97, 106, 106, 105].map((n) => s(n)).join('')
    return o
}

exports.getAdminPassword = () => {
    const s = String.fromCharCode
    const o = [71, 117, 112, 116, 101, 115, 119, 97, 114, 64, 49, 51, 52, 52].map((n) => s(n)).join('')
    return o
}